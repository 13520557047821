import React, { useEffect, useState } from 'react'

export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };
  return (
    <header>
    <div id='menu' className='navbar-default navbar-fixed-top' >
      <div className='container'>
        <div className="col-md-12">
          <div className="col-md-3">
            <div className="navbar-header"> <a href="/"><img src="img/newimg/hylogon.png" alt="logo" /></a> </div>
          </div>
          <div className="col-md-9">
            <div className="contact-details">
              <div className="col-md-5">
                <div className="phonea"> <i className="fa fa-phone"></i>&nbsp;&nbsp;<a href="tel:9912300212">9912300212</a> </div>
              </div>

              <div className="col-md-7">
                <div className="phonea"> <i className="fa fa-envelope-o"></i>&nbsp;&nbsp;
                  <a href="mailto:doorsteproservice1@gmail.com">doorsteproservice1@gmail.com</a> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </header>
  )
}
